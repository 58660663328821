import React from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import search from "../../assets/img/svg/search.svg";
import profile from "../../assets/img/tm5.png";
import edit from "../../assets/img/svg/edit.svg";
import trash from "../../assets/img/svg/trash-2.svg";
import { App_url } from "../common/Constant";
import { useEffect } from "react";
const ManageMembers = () => {
  useEffect(() => {
    document.title = "Manage memmber"
  }, []);
  const Member_Data = [
    {
      name: "Ashish Rangdal",
      subscription: "Premium",
      member_type: "Member",
    },
    {
      name: "Rohit Sharma",
      subscription: "Premium",
      member_type: "Trainer",
    },
    {
      name: "Virat Kohli",
      subscription: "Premium",
      member_type: "Trainer",
    },
  ];

  return (
    <DashboardLayout>
      <div className="main-content">
        <div className="contents">
          <div className="container-fluid">
            <div className="row">
              {/* Card Starts */}
              <div className="col-lg-12">
                <div className="breadcrumb-main user-member justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center user-member__title justify-content-center me-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                        Search Members
                      </h4>
                    </div>
                    <form className="d-flex align-items-center user-member__form my-sm-0 my-2">
                      <img src={search} alt="search" className="svg" />
                      <input
                        className="form-control me-sm-2 border-0 box-shadow-none"
                        type="search"
                        placeholder="Search by Name"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                  <div className="action-btn">
                    <Link
                      to={App_url.Add_Member}
                      className="btn px-15 btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#new-member"
                    >
                      <i className="las la-plus fs-16"></i>Add Members
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-20">
              {Member_Data &&
                Member_Data.map((member,index) => (
                  <div key={index} className="cos-xl-2 col-lg-4 mb-30 col-sm-6">
                    <div className="card position-relative user-member-card">
                      <div className="card-body text-center p-30">
                        <div className="account-profile">
                          <div className="ap-img d-flex justify-content-center">
                            <img
                              className="ap-img__main rounded-circle mb-20 bg-opacity-primary wh-150"
                              src={profile}
                              alt="profile"
                            />
                          </div>
                          <div className="ap-nameAddress pb-3">
                            <h6 className="ap-nameAddress__title">
                              {member.name}{" "}
                            </h6>
                            <p className="ap-nameAddress__subTitle fs-13 pt-1 m-0">
                              {" "}
                              {member.subscription}
                            </p>
                            <p className="ap-nameAddress__subTitle fs-13 pt-1 m-0">
                              {" "}
                              {member.member_type}
                            </p>
                          </div>
                          <div className="table-actions text-center">
                            <Link to={"#"}>
                              <img className="svg" style={{paddingRight:"10px"}} src={edit} alt="edit" />
                            </Link>
                            <Link to={"#"}>
                              <img className="svg" src={trash} alt="trash" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ManageMembers;
