import React from 'react';
import { Link } from 'react-router-dom';
import folder from '../../assets/img/svg/folder.svg'
import DashboardLayout from '../layouts/DashboardLayout';
import bookmark from '../../assets/img/bookmark.png'
import { App_url } from '../common/Constant';
import { useEffect } from 'react';
const GymTourPage = () => {
  useEffect(() => { 
    document.title = "Gym tour"
  }, []);
  return (
    <DashboardLayout>
    <div className='main-content'>
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-main">
                <h4 className="text-capitalize breadcrumb-title">Gallery</h4>
              </div>
            </div>
          </div>
          <div className="bookmark-page bookmark-page--grid mb-30">
            <div className="row">
              <div className="columns-1 col-lg-4 col-md-5 col-sm-12">
                <div className="bookmark-sidebar">
                  <div className="note-sidebar">
                    <div className="card border-0 shadow-none">
                      <div className="card-body px-15 pt-30">
                        <div className="px-3">
                          <Link to={App_url.Gym_Tour_Add} className="btn btn-primary btn-default btn-rounded btn-block" data-bs-toggle="modal" data-bs-target="#taskModal">
                          <i className="las la-plus fs-16"></i>
                            Add Image
                          </Link>
                        </div>
                        <div className="note-types task-types">
                          <ul className="nav mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <Link className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" to="#" role="tab" aria-selected="true">
                                <img src={folder} alt="folder" className="svg mr-4"/>
                                Machinaries
                              </Link>
                            </li>
                            <li className="nav-item" role="presentation">
                              <Link className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" to="#" role="tab" aria-selected="false">
                                <img src={folder} alt="folder" className="svg mr-4"  />
                                Dumbells
                              </Link>
                            </li>
                            <li className="nav-item" role="presentation">
                              <Link className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" to="#" role="tab" aria-selected="false">
                                <img src={folder} alt="folder" className="svg mr-4" />
                                Yoga
                              </Link>
                            </li>
                            <li className="nav-item" role="presentation">
                              <Link className="nav-link" id="pills-deleted-tab" data-bs-toggle="pill" to="#" role="tab" aria-selected="false">
                                <img src={folder} alt="folder" className="svg mr-4"  />
                                Architecture
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns-2 col-lg-8 col-md-7 col-sm-12 mt-sm-30 mt-md-0">
                <div className="bookmark-page__list">
                  <div className="bookmark-single">
                    <div className="bookmark-card card">
                      <div className="card-header">
                        <h6 className="fs-500">Machinaries</h6>
                      </div>
                      <div className="card-body pt-30">
                        <div className="tab-content" id="pills-tabContent">
                          <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className="row mx-n1">
                              <div className="cus-xl-3 col-lg-6 col-md-12 col-sm-6 col-12 mb-30">
                                <div className="card bookmark bookmark--grid">
                                  <div className="bookmark__image">
                                    
                                    <Link to="#"><img className="card-img-top img-fluid" src={bookmark} alt="digital-chair" /></Link>
                                  </div>
                                  <div className="card-body px-15 py-20">
                                   
                                    <div className="bookmark__button d-flex mt-15 flex-wrap">
                                      <button className="btn btn-primary btn-sm btn-squared border-0 " data-bs-toggle="modal" data-bs-target="#taskModal2">Edit</button>
                                      <button className="btn btn-sm btn-squared btn-outline-light color-light px-15 color-light black-text">Remove</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cus-xl-3 col-lg-6 col-md-12 col-sm-6 col-12 mb-30">
                                <div className="card bookmark bookmark--grid">
                                  <div className="bookmark__image">
                                    
                                    <Link to="#"><img className="card-img-top img-fluid" src={bookmark} alt="digital-chair" /></Link>
                                  </div>
                                  <div className="card-body px-15 py-20">
                                   
                                    <div className="bookmark__button d-flex mt-15 flex-wrap">
                                      <button className="btn btn-primary btn-sm btn-squared border-0 " data-bs-toggle="modal" data-bs-target="#taskModal2">Edit</button>
                                      <button className="btn btn-sm btn-squared btn-outline-light color-light px-15 color-light black-text">Remove</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cus-xl-3 col-lg-6 col-md-12 col-sm-6 col-12 mb-30">
                                <div className="card bookmark bookmark--grid">
                                  <div className="bookmark__image">
                                    
                                    <Link to="#"><img className="card-img-top img-fluid" src={bookmark} alt="digital-chair" /></Link>
                                  </div>
                                  <div className="card-body px-15 py-20">
                                   
                                    <div className="bookmark__button d-flex mt-15 flex-wrap">
                                      <button className="btn btn-primary btn-sm btn-squared border-0 " data-bs-toggle="modal" data-bs-target="#taskModal2">Edit</button>
                                      <button className="btn btn-sm btn-squared btn-outline-light color-light px-15 color-light black-text">Remove</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cus-xl-3 col-lg-6 col-md-12 col-sm-6 col-12 mb-30">
                                <div className="card bookmark bookmark--grid">
                                  <div className="bookmark__image">
                                    
                                    <Link to="#"><img className="card-img-top img-fluid" src={bookmark} alt="digital-chair" /></Link>
                                  </div>
                                  <div className="card-body px-15 py-20">
                                   
                                    <div className="bookmark__button d-flex mt-15 flex-wrap">
                                      <button className="btn btn-primary btn-sm btn-squared border-0 " data-bs-toggle="modal" data-bs-target="#taskModal2">Edit</button>
                                      <button className="btn btn-sm btn-squared btn-outline-light color-light px-15 color-light black-text">Remove</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cus-xl-3 col-lg-6 col-md-12 col-sm-6 col-12 mb-30">
                                <div className="card bookmark bookmark--grid">
                                  <div className="bookmark__image">
                                    
                                    <Link to="#"><img className="card-img-top img-fluid" src={bookmark} alt="digital-chair" /></Link>
                                  </div>
                                  <div className="card-body px-15 py-20">
                                   
                                    <div className="bookmark__button d-flex mt-15 flex-wrap">
                                      <button className="btn btn-primary btn-sm btn-squared border-0 " data-bs-toggle="modal" data-bs-target="#taskModal2">Edit</button>
                                      <button className="btn btn-sm btn-squared btn-outline-light color-light px-15 color-light black-text">Remove</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="cus-xl-3 col-lg-6 col-md-12 col-sm-6 col-12 mb-30">
                                <div className="card bookmark bookmark--grid">
                                  <div className="bookmark__image">
                                    
                                    <Link to="#"><img className="card-img-top img-fluid" src={bookmark} alt="digital-chair" /></Link>
                                  </div>
                                  <div className="card-body px-15 py-20">
                                   
                                    <div className="bookmark__button d-flex mt-15 flex-wrap">
                                      <button className="btn btn-primary btn-sm btn-squared border-0 " data-bs-toggle="modal" data-bs-target="#taskModal2">Edit</button>
                                      <button className="btn btn-sm btn-squared btn-outline-light color-light px-15 color-light black-text">Remove</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </DashboardLayout>
  );
}

export default GymTourPage;
