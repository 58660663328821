import React from "react";
import { useState } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import { Row } from "react-bootstrap";
import InputGroup from "../common/InputGroup";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AddSubscriptionMemberForm = () => {
  useEffect(() => {
    document.title = "Add subscription"
  }, []);
  const userList = [
    {
      title: "",
      value: "",
    },
    {
      title: "Ashish",
      value: "Ashish",
    },
    {
      title: "Rohit",
      value: "Rohit",
    },
    {
      title: "Virat",
      value: "Virat",
    },
    {
      title: "Jasprit",
      value: "Jasprit",
    },
    {
      title: "Bhuvi",
      value: "Bhuvi",
    },
    {
      title: "Shubhman",
      value: "Shubhman",
    },
    {
      title: "Ishan",
      value: "Ishan",
    },
  ];
  const paymentModeList = [
    {
      title: "",
      value: "",
    },
    {
      title: "Online",
      value: "Online",
    },
    {
      title: "Offline",
      value: "Offline",
    },
  ];
  const amountList = [
    {
      title: "",
      value: "",
    },
    {
      title: "$49",
      value: "49",
    },
    {
      title: "$99",
      value: "99",
    },
    {
      title: "$199",
      value: "199",
    },
  ];
  const planList = [
    {
      title: "",
      value: "",
    },
    {
      title: "Basic",
      value: "Basic",
    },
    {
      title: "Standard",
      value: "Standard",
    },
    {
      title: "Premium",
      value: "Premium",
    },
  ];



  const navigate = useNavigate();
  const [error, setError] = useState({
    select_user: "",
    select_plan: "",
    select_amount: "",
    select_paymont_mode: "",
  });
  const [formData, setFormData] = useState({
    select_user: "",
    select_plan: "",
    select_amount: "",
    select_paymont_mode: "",
  });

  function changeHandler(event) {
    const { name, value, checked, type } = event.target;
    switch (name) {
      case "select_user":
        error.select_user = value.length < 2 ? "Name must have more characters" : "";
        break;
      case "select_plan":
        error.select_plan =
          value === "" ? "Contact number should have 10 digit" : "";
        break;
      case "select_amount":
        error.select_amount =
        value === ""
            ? "Password must be at least 8 characters long!"
            : "";
        break;
      case "select_paymont_mode":
        error.select_paymont_mode = value === "" ? "Mention Your DOB" : "";
        break;
      default:
        break;
    }
    setFormData((prevFormData) => {
      // console.log(prevFormData);
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }
  const checkValidation = () => {
    let value = true;
    if (formData?.select_amount === "") {
      error.select_amount = "Please enter amount";
      value = false;
    }
    if (formData?.select_paymont_mode === "") {
      error.select_paymont_mode = "Please enter payment mode";
      value = false;
    }
    if (formData?.select_plan === "") {
      error.select_plan = "Please enter plan";
      value = false;
    }
    if (formData?.select_user === "") {
      error.select_user = "Please enter user name";
      value = false;
    }
    setError((data) => ({
      ...data,
      ...error,
    }));
    return value;
  };
  function submitHandler(e) {
    e.preventDefault();
    if (checkValidation()) {
      console.log("Printing Form-->>", formData);
      navigate("/");
    } else {
      console.log("Please Enter Data");
    }
  }

  // useEffect(()=>{
  //   if(location?.state!==null){
  //     setFormData({
  //       ...location?.state
  //     })
  //   }
  // },[])

    // const submitHandler = (e) => {
    //   e.preventDefault();
    //   if (checkValidation()) {
    //       if(location?.state===null){
    //         const payload = {
    //           id: uuidV4(),
    //           select_user:formData.select_user,
    //           select_plan:formData.select_plan,
    //           select_amount:formData.select_amount,
    //           select_paymont_mode:formData.select_paymont_mode
    //         }
    //         dispatch(setStoreAddSubscriber(payload));  
    //       }
    //       else{
    //         const payload = {
    //           id: location?.state?.id,
    //           select_user:formData.select_user,
    //           select_plan:formData.select_plan,
    //           select_amount:formData.select_amount,
    //           select_paymont_mode:formData.select_paymont_mode
    //         }
    //         console.log("Pyaload",payload)
    //         dispatch(setStoreEditSubscriber(payload))

    //       }
    //       navigate("/subscriber")
    //     }
    // };
    // console.log("location", location)
  return (
    <DashboardLayout>
      <div className="main-content">
        <div className="contents">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center user-member__title mb-30 mt-30">
                  <h4 className="text-capitalize">Info</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="user-info-tab w-100 bg-white global-shadow radius-xl mb-50">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade  show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="row justify-content-center">
                        <div className="col-xxl-4 col-10">
                          <div className="mt-sm-40 mb-sm-50 mt-20 mb-20">
                            <div className="user-tab-info-title mb-sm-40 mb-20 text-capitalize">
                              <h5 className="fw-500">
                                Subscription Information
                              </h5>
                            </div>

                            <div className="edit-profile__body">
                              <form onSubmit={submitHandler}>
                                <Row>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                    <InputGroup
                                        label="Select User"
                                        inputType="select"
                                        option={userList}
                                        name={"select_user"}
                                        error={error.select_user}
                                        value={formData.select_user}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                    <InputGroup
                                        label="Select Plan"
                                        inputType="select"
                                        option={planList}
                                        name={"select_plan"}
                                        error={error.select_plan}
                                        value={formData.select_plan}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                </Row>

                                <Row>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                    <InputGroup
                                        label="Select Amount"
                                        inputType="select"
                                        option={amountList}
                                        name={"select_amount"}
                                        error={error.select_amount}
                                        value={formData.select_amount}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                    <InputGroup
                                        label="Payment Mode"
                                        inputType="select"
                                        option={paymentModeList}
                                        name={"select_paymont_mode"}
                                        error={error.select_paymont_mode}
                                        value={formData.select_paymont_mode}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                </Row>

                                <div className="button-group d-flex pt-sm-25 justify-content-md-end justify-content-start ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-default btn-squared text-capitalize radius-md shadow2 btn-sm"
                                  >
                                    Save &amp; Next
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddSubscriptionMemberForm;
