import React from "react";
import { useState } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import { Row } from "react-bootstrap";
import InputGroup from "../common/InputGroup";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { App_url } from "../common/Constant";

const AddMember = () => {
  useEffect(() => {
    document.title = "Add member"
  }, []);
  const heightTypes = [
    {
      title: "",
      value: "",
    },
    {
      title: "CM",
      value: "CM",
    },
    {
      title: "FT",
      value: "FT",
    },
  ];
  const weightTypes = [
    {
      title: "",
      value: "",
    },
    {
      title: "LBS",
      value: "LBS",
    },
    {
      title: "KG",
      value: "KG",
    },
  ];
  const roleTypes = [
    {
      title: "",
      value: "",
    },
    {
      title: "Member",
      value: "Member",
    },
    {
      title: "Trainer",
      value: "Trainer",
    },
  ];
  const genderTypes = [
    {
      title: "",
      value: "",
    },
    {
      title: "Male",
      value: "Male",
    },
    {
      title: "Female",
      value: "Female",
    },
    {
      title: "Other",
      value: "Other",
    },
  ];
  const healthType = [
    {
      title: "",
      value: "",
    },
    {
      title: "Ashthama",
      value: "Ashthama",
    },
    {
      title: "Diabetic",
      value: "Diabetic",
    },
    {
      title: "Other",
      value: "Other",
    },
  ];

  const navigate = useNavigate();
  const [error, setError] = useState({
    name: "",
    role: "",
    health: "",
    password: "",
    contact: "",
    weight_type: "",
    height_type: "",
    weight: "",
    height: "",
    emergency_contact: "",
    emergency_contact_name: "",
    dob: "",
    gender: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    role: "",
    health: "",
    password: "",
    contact: "",
    weight_type: "",
    height_type: "",
    weight: "",
    height: "",
    emergency_contact: "",
    emergency_contact_name: "",
    dob: "",
    gender: "",
  });

  function changeHandler(event) {
    const { name, value, checked, type } = event.target;
    switch (name) {
      case "name":
        error.name = value.length < 2 ? "Name must have more characters" : "";
        break;
      case "contact":
        error.contact =
          value.length === 10 ? "" : "Contact number should have 10 digit";
        break;
      case "password":
        error.password =
          value.length < 8
            ? "Password must be at least 8 characters long!"
            : "";
        break;
      case "dob":
        error.dob = value === "" ? "Mention Your DOB" : "";
        break;
      case "emergency_contact":
        error.emergency_contact =
          value.length !== 10 ? "Contact number should have 10 digit" : "";
        break;
      case "emergency_contact_name":
        error.emergency_contact_name =
          value.length < 2 ? "Name must have more characters" : "";
        break;
      case "weight":
        error.weight = value < 10 ? "Weight not Match" : "";
        break;
      case "height":
        error.height = value < 1 ? "Height not Match" : "";
        break;
      default:
        break;
    }
    setFormData((prevFormData) => {
      // console.log(prevFormData);
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }
  const checkValidation = () => {
    let value = true;
    if (formData?.name === "") {
      error.name = "Please enter your name";
      value = false;
    }
    if (formData?.contact === "") {
      error.contact = "Please enter your contact number";
      value = false;
    }
    if (formData?.dob === "") {
      error.dob = "Please enter your DOB";
      value = false;
    }
    if (formData?.emergency_contact === "") {
      error.emergency_contact = "Please enter your emergency contact number";
      value = false;
    }
    if (formData?.gender === "") {
      error.gender = "Please enter your gender";
      value = false;
    }
    if (formData?.health === "") {
      error.health = "Please enter your health type";
      value = false;
    }
    if (formData?.weight === "") {
      error.weight = "Please enter your weight";
      value = false;
    }
    if (formData?.height === "") {
      error.height = "Please enter your height";
      value = false;
    }
    if (formData?.weight_type === "") {
      error.weight_type = "Please enter your weight type";
      value = false;
    }
    if (formData?.height_type === "") {
      error.height_type = "Please enter your height type";
      value = false;
    }
    setError((data) => ({
      ...data,
      ...error,
    }));
    return value;
  };
  function submitHandler(e) {
    e.preventDefault();
    if (checkValidation()) {
      console.log("Printing Form-->>", formData);
      navigate(App_url.Member_Management);
    } else {
      console.log("Please Enter Data");
    }
  }

  return (
    <DashboardLayout>
      <div className="main-content">
        <div className="contents">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center user-member__title mb-30 mt-30">
                  <h4 className="text-capitalize">Profile Info</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="user-info-tab w-100 bg-white global-shadow radius-xl mb-50">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade  show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="row justify-content-center">
                        <div className="col-xxl-4 col-10">
                          <div className="mt-sm-40 mb-sm-50 mt-20 mb-20">
                            <div className="user-tab-info-title mb-sm-40 mb-20 text-capitalize">
                              <h5 className="fw-500">Personal Information</h5>
                            </div>

                            <div className="edit-profile__body">
                              <form onSubmit={submitHandler}>
                                <Row>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Name"
                                        type="text"
                                        placeholder={"Name"}
                                        error={error.name}
                                        name={"name"}
                                        onChange={changeHandler}
                                        value={formData.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Gender"
                                        inputType="select"
                                        option={genderTypes}
                                        name={"gender"}
                                        error={error.gender}
                                        value={formData.gender}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                </Row>

                                <Row>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Contact Number"
                                        type="number"
                                        placeholder={"1234567890"}
                                        name={"contact"}
                                        onChange={changeHandler}
                                        value={formData.contact}
                                        error={error.contact}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="DOB"
                                        type="date"
                                        placeholder={"dd/mm/yyyy"}
                                        name={"dob"}
                                        error={error.dob}
                                        onChange={changeHandler}
                                        value={formData.dob}
                                      />
                                    </div>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Emergency Contact Name"
                                        type="text"
                                        error={error.emergency_contact_name}
                                        placeholder={"Contact Name"}
                                        name={"emergency_contact_name"}
                                        onChange={changeHandler}
                                        value={formData.emergency_contact_name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Emergency Contact Number"
                                        type="tel"
                                        placeholder={"+91 1234567890"}
                                        name={"emergency_contact"}
                                        error={error.emergency_contact}
                                        onChange={changeHandler}
                                        value={formData.emergency_contact}
                                      />
                                    </div>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                    <InputGroup
                                        label="Health Condition"
                                        inputType="select"
                                        option={healthType}
                                        name={"health"}
                                        error={error.health}
                                        value={formData.health}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                    <InputGroup
                                        label="Choose Role"
                                        inputType="select"
                                        option={roleTypes}
                                        name={"role"}
                                        error={error.role}
                                        value={formData.role}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                </Row>

                                <Row>
                                  <div className="col-12 col-md-3">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Weight Type"
                                        inputType="select"
                                        option={weightTypes}
                                        name={"weight_type"}
                                        error={error.weight_type}
                                        value={formData.weight_type}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-9">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        type="number"
                                        label="Enter Weight"
                                        inputType={"number"}
                                        placeholder={formData.weight_type}
                                        name={"weight"}
                                        error={error.weight}
                                        onChange={changeHandler}
                                        value={formData.weight}
                                      />
                                    </div>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="col-12 col-md-3">
                                    <div className="form-group mb-25">
                                    <InputGroup
                                        label="Height Type"
                                        inputType="select"
                                        option={heightTypes}
                                        name={"height_type"}
                                        error={error.height_type}
                                        value={formData.height_type}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-9">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        type="number"
                                        label="Enter Height"
                                        inputType={"number"}
                                        placeholder={formData.height_type}
                                        name={"height"}
                                        error={error.height}
                                        onChange={changeHandler}
                                        value={formData.height}
                                      />
                                    </div>
                                  </div>
                                </Row>

                                <div className="button-group d-flex pt-sm-25 justify-content-md-end justify-content-start ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-default btn-squared text-capitalize radius-md shadow2 btn-sm"
                                  >
                                    Save &amp; Next
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddMember;
