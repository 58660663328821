import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { ArcElement } from "chart.js";
Chart.register(ArcElement);
export default function PieChart() {
  const data = {
    labels: ["Leg", "Chest", "Yoga", "Push-Ups", "Thie", "Wrist"],
    datasets: [
      {
        label: "# of Votes",
        data: [35, 25, 22, 20, 18, 15],
        backgroundColor: [
          "#007D9C",
          "#244D70",
          "#D123B3",
          "#F7E018",
          "#fff",
          "#FE452A"
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)"
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 md-6">
              <div className="card border-0 project-task-list--event">
              <div
            className="mx-auto mt-30 mb-35"
            style={{ width: 450, textAlign: "center" }}
          >
          <h1 style={{ fontFamily: "monospace" }}>
            Most Popular Body Part Workouts to Learn in 2023
          </h1>
          <Pie data={data} width={50} height={50} />
          </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
