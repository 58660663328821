import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { App_url } from "../common/Constant";
import InputGroup from "../common/InputGroup";
import DashboardLayout from "../layouts/DashboardLayout";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
const AddNutritionPage = () => {
  const categoryOptions = [
    {
      title: "",
      value: "",
    },
    {
      title: "Free",
      value: "Free",
    },
    {
      title: "Paid",
      value: "Paid",
    },
  ];
  useEffect(() => {
    document.title = "Add nutrition";
  }, []);
  const navigate = useNavigate();
  const [error, setError] = useState({
    desc: "",
    category: "",
    cal: "",
  });
  const [formData, setFormData] = useState({
    desc: "",
    category: "",
    cal: "",
  });

  function changeHandler(event) {
    const { name, value, checked, type } = event.target;
    switch (name) {
      case "desc":
        error.desc = value.length < 2 ? "Enter description" : "";
        break;
      case "cal":
        error.cal = value.length === "" ? "Enter Calories" : "";
        break;

      case "category":
        error.category = value === "" ? "Enter Category" : "";
        break;
      default:
        break;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  const checkValidation = () => {
    let value = true;
    if (formData?.category === "") {
      error.category = "Please enter category";
      value = false;
    }
    if (formData?.cal === "") {
      error.cal = "Please enter Calories";
      value = false;
    }
    if (formData?.desc === "") {
      error.desc = "Please enter description";
      value = false;
    }
    setError((data) => ({
      ...data,
      ...error,
    }));
    return value;
  };

  function submitHandler(e) {
    e.preventDefault();
    if (checkValidation()) {
      console.log("Printing Form-->>", formData);
      navigate(`${App_url.Gym_Tour}`);
    } else {
      console.log("Please Enter Data");
    }
  }

  return (
    <DashboardLayout>
      <div className="main-content">
        <div className="contents">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center user-member__title mb-30 mt-30">
                  <h4 className="text-capitalize">Add Nutrition</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="user-info-tab w-100 bg-white global-shadow radius-xl mb-50">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade  show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="row justify-content-center">
                        <div className="col-xxl-4 col-10">
                          <div className="mt-sm-40 mb-sm-50 mt-20 mb-20">
                            <div className="user-tab-info-title mb-sm-40 mb-20 text-capitalize">
                              <h5 className="fw-500">Choose Image</h5>
                            </div>

                            <div className="edit-profile__body">
                              <form onSubmit={submitHandler}>
                                <div className="form-group mb-25">
                                  <label
                                    htmlFor="images"
                                    className="drop-container"
                                  >
                                    <span className="drop-title">
                                      Drop files here
                                    </span>
                                    or
                                    <input
                                      type="file"
                                      name="image"
                                      onChange={changeHandler}
                                      id="images"
                                      accept="image/*"
                                      required
                                    />
                                  </label>
                                </div>
                                <div className="form-group mb-25">
                                  <InputGroup
                                    label="Nutrition Description"
                                    type="text"
                                    name={"desc"}
                                    error={error.desc}
                                    value={formData.desc}
                                    onChange={changeHandler}
                                  />
                                </div>
                                <Row>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Calories Earned / Hour"
                                        type="number"
                                        name={"cal"}
                                        error={error.cal}
                                        value={formData.cal}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Category"
                                        inputType={"select"}
                                        name={"category"}
                                        option={categoryOptions}
                                        error={error.category}
                                        value={formData.category}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                </Row>
                                <div className="button-group d-flex pt-sm-25 justify-content-md-end justify-content-start ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-default btn-squared text-capitalize radius-md shadow2 btn-sm"
                                  >
                                    Save &amp; Next
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddNutritionPage;
