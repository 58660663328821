import React from "react";
import { Link } from "react-router-dom";
import author from "../../assets/img/author-nav.jpg";
import logo from "../../assets/img/appristine-logo.png";
import { App_url } from "../common/Constant";
import toggle from "../../assets/img/svg/align-center-alt.svg";

const Header = (props) => {
  const onClickMenu = () => {
    props?.setShowSideBar(!props?.ShowSideBar);
  };
  return (
    <div>
      <header className="header-top">
        <nav className="navbar navbar-light">
          <div className="navbar-left">
            <div className="logo-area">
              <Link className="navbar-brand" to="/">
                <img className="dark" src={logo} alt="logo" />
              </Link>
              <Link onClick={onClickMenu} className="sidebar-toggle">
                <img className="svg" src={toggle} alt="img" />
              </Link>
            </div>
          </div>

          <div className="navbar-right">
            <ul className="navbar-right__menu">
              <li className="nav-author">
                <div className="dropdown-custom">
                  <Link className="nav-item-toggle">
                    <img src={author} alt="" className="rounded-circle" />
                    <span className="nav-item__title">
                      Ashish
                      <i className="las la-angle-down nav-item__arrow"></i>
                    </span>
                  </Link>
                  <div className="dropdown-parent-wrapper">
                    <div className="dropdown-wrapper">
                      <div className="nav-author__info">
                        <div className="author-img">
                          <img src={author} alt="" className="rounded-circle" />
                        </div>
                        <div>
                          <h6>Ashish Rangdal</h6>
                          <span>Web Developer</span>
                        </div>
                      </div>
                      <div
                        className="nav-author__options"
                        style={{ lineHeight: "2" }}
                      >
                        <ul>
                          <li>
                            <Link to={App_url.Member_Management}>
                              <i className="uil uil-user"></i> Members
                            </Link>
                          </li>
                          <li>
                            <Link to={App_url.Monthly_Subscription}>
                              <i className="uil uil-setting"></i>
                              Subscriptions
                            </Link>
                          </li>
                          <li>
                            <Link to={App_url.Gym_Tour}>
                              <i className="uil uil-key-skeleton"></i> Gym Tour
                            </Link>
                          </li>
                          <li>
                            <Link to={App_url.Booking_Private_Session}>
                              <i className="uil uil-users-alt"></i> Booking
                              session
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
