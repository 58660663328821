import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
function DashboardLayout(props) {
  const [ShowSideBar, setShowSideBar] = useState(true)
  return (
    <div>
      
      <Header setShowSideBar={setShowSideBar} ShowSideBar={ShowSideBar} />
      <Sidebar setShowSideBar={setShowSideBar} ShowSideBar={ShowSideBar} />

      <main className="main-content">
        {props?.children}

        {/* <footer className="footer-wrapper">
          <div className="footer-wrapper__inside">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-copyright">
                    <p>
                      <span>© 2023</span>
                      <Link to="#">Sovware</Link>
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-menu text-end">
                    <ul>
                      <li>
                        <Link to="#">About</Link>
                      </li>
                      <li>
                        <Link to="#">Team</Link>
                      </li>
                      <li>
                        <Link to="#">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
      </main>
    </div>
  );
}
export default DashboardLayout;
