import React from "react";
import { NavLink } from "react-router-dom";
import { App_url } from "../common/Constant";

const Sidebar = (props) => {
  const ListHeading = [
    {
      title: "Dashboard",
      to: `${App_url.Dashboard}`,
      iconclass: "bi bi-shield-fill",
    },
    {
      title: "Member Management",
      to: `${App_url.Member_Management}`,
      iconclass: "bi bi-people-fill",
    },
    {
      title: " Manage Subscription",
      to: `${App_url.Monthly_Subscription}`,
      iconclass: "bi bi-plus-square-fill  ",
    },
    {
      title: "Book Private Session",
      to: `${App_url.Booking_Private_Session}`,
      iconclass: "bi bi-calendar2-check",
    },
    {
      title: "Workout Plans",
      to: `${App_url.Workout_Plan}`,
      iconclass: "bi bi-bicycle",
    },
    {
      title: "Nutrition Plans",
      to: `${App_url.Nutrition_Plan}`,
      iconclass: "bi bi-egg",
    },
    {
      title: "Gym Tour",
      to: `${App_url.Gym_Tour}`,
      iconclass: "bi bi-eyeglasses",
    },
  ];

  return (
    <div>
      <div className={`sidebar-wrapper ${props?.ShowSideBar?"":"d-none"}`}>
        <div className="sidebar sidebar-collapse" id="sidebar">
          <div className="sidebar__menu-group">
            <ul className="sidebar_nav">
              {ListHeading?.map((item, index) => (
                <li key={index}>
                  <NavLink to={item.to}>
                    <i
                      className={`nav-icon ${item.iconclass}`}
                      style={{ marginRight: "0.6rem" }}
                    ></i>
                    <span className="menu-text">{item.title}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
