export const ActionTypes = {
    SET_STORE_USER_LOGIN:"SET_STORE_USER_LOGIN",
    SET_STORE_ADD_MEMBER:"SET_STORE_ADD_MEMBER",
    SET_STORE_EDIT_MEMBER:"SET_STORE_EDIT_MEMBER",
    SET_STORE_DELETE_MEMBER:"SET_STORE_DELETE_MEMBER",
    SET_STORE_ADD_TRAINER:"SET_STORE_ADD_TRAINER",   
    SET_STORE_EDIT_TRAINER:"SET_STORE_EDIT_TRAINER",
    SET_STORE_DELETE_TRAINER:"SET_STORE_DELETE_TRAINER",
}
export const setStoreCuurentUser=(data)=>{
    return {
        type:ActionTypes.SET_STORE_USER_LOGIN,
        payload:data,
    }
}

export const setStoreAddMember =(data)=>{
    return {
        type: ActionTypes.SET_STORE_ADD_MEMBER,
        payload: data
    }
}
export const setStoreEditMember = (data) => {
    return {
        type: ActionTypes.SET_STORE_EDIT_MEMBER,
        payload: data,
    }
}
export const setStoreDeleteMember = (id) => {
    return {
        type: ActionTypes.SET_STORE_DELETE_MEMBER,
        payload: id,
    }
}
export const setStoreAddTrainer =(data)=>{
    return {
        type: ActionTypes.SET_STORE_ADD_TRAINER,
        payload: data
    }
}
export const setStoreEditTrainer = (data) => {
    return {
        type: ActionTypes.SET_STORE_EDIT_TRAINER,
        payload: data,
    }
}
export const setStoreDeleteTrainer = (id) => {
    return {
        type: ActionTypes.SET_STORE_DELETE_TRAINER,
        payload: id,
    }
}

