import React from "react";
import DashboardLayout from "./DashboardLayout";
import { MonthlySubscriptionChild } from "../monthly-subscription/MonthlySubscription";
import LineChart from "../charts/LineChart";
import BarChart from "../charts/BarChart";
import PieChart from "../charts/PieChart";
import { useEffect } from "react";
import ClassesAndSchedule from "../common/DashboardData/ClassesAndSchedule";
import SubscriptionPieChart from "../common/DashboardData/SubscriptionPieChart";

const Dashboard = () => {
  useEffect(() => {
    document.title = "Dashboard";
  }, []);
  return (
    <DashboardLayout>
      <MonthlySubscriptionChild/>
      <ClassesAndSchedule></ClassesAndSchedule>
      <SubscriptionPieChart></SubscriptionPieChart>
      <PieChart></PieChart>
      <LineChart></LineChart>
      <BarChart></BarChart>
      
    </DashboardLayout>
  );
};

export default Dashboard;
