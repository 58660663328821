import React from "react";
import Chart from "chart.js/auto";
import { ArcElement } from "chart.js";
import { Bar } from "react-chartjs-2";
Chart.register(ArcElement);

const BarChart = () => {
  const labels = ["January", "February", "March", "April", "May", "June"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: [2, 10, 51, 29, 20, 95],
      },
    ],
  };
  return (
    <div className="contents">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 md-6">
            <div className="card border-0 project-task-list--event">
              <div
                className="mx-auto mt-30 mb-35"
                style={{ width: 850,height: 450, textAlign:"center" }}
              >
                <Bar data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
