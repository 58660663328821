import React from "react";
import { useState } from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import InputGroup from "../common/InputGroup";
import { useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import { App_url } from "../common/Constant";
import { useEffect } from "react";

const BookPrivateSessionPage = () => {
  useEffect(() => {
    document.title = "Add booking session"
  }, []);
  const dayTypes = [
    {
      title: "",
      value: "",
    },
    {
      title: "Monday",
      value: "Monday",
    },
    {
      title: "Tuesday",
      value: "Tuesday",
    },
    {
      title: "Wednesday",
      value: "Wednesday",
    },
    {
      title: "Thursday",
      value: "Thursday",
    },
    {
      title: "Friday",
      value: "Friday",
    },
    {
      title: "Saturday",
      value: "Saturday",
    },
    {
      title: "Sunday",
      value: "Sunday",
    },
  ];
  const dayWeek = [
    {
      title: "",
      value: "",
    },
    {
      title: "One Day",
      value: true,
    },
    {
      title: "Bi-Weekly",
      value: false,
    },
  ];
  const trainerTypes = [
    {
      title: "",
      value: "",
    },
    {
      title: "Ashish",
      value: "Ashish",
    },
    {
      title: "Rohit",
      value: "Rohit",
    },
    {
      title: "Virat",
      value: "Virat",
    },
    {
      title: "Jasprit",
      value: "Jasprit",
    },
    {
      title: "Bhuvi",
      value: "Bhuvi",
    },
    {
      title: "Shubhman",
      value: "Shubhman",
    },
    {
      title: "Ishan",
      value: "Ishan",
    },
  ];
  const paymentTypes = [
    {
      title: "",
      value: "",
    },
    {
      title: "Online",
      value: "Online",
    },
    {
      title: "Offline",
      value: "Offline",
    },
  ];
  const sessionDurationTypes = [
    {
      title: "",
      value: "",
    },
    {
      title: "30:Min",
      value: "30",
    },
    {
      title: "60:Min",
      value: "60",
    },
    {
      title: "90:Min",
      value: "90",
    },
  ];
  const navigate = useNavigate();
  const [error, setError] = useState({
    contact: "",
    name: "",
    trainer: "",
    time: "",
    date: "",
    address: "",
    duration: "",
    paymentMode: "",
  });
  const [formData, setFormData] = useState({
    contact: "",
    name: "",
    trainer: "",
    time: "",
    date: "",
    address: "",
    duration: "",
    day: true,
    selectDay: "",
    paymentMode: "",
  });

  function changeHandler(event) {
    const { name, value, checked, type } = event.target;
    switch (name) {
      case "name":
        error.name = value.length < 2 ? "Name must have more characters" : "";
        break;
      case "contact":
        error.contact =
          value.length === 10 ? "" : "Contact number should have 10 digit";
        break;

      case "trainer":
        error.trainer = value === "" ? "Please choose trainer" : "";
        break;
      case "paymentMode":
        error.paymentMode = value === "" ? "Please choose payment mode" : "";
        break;
      case "time":
        error.time = value === "" ? "Please choose time" : "";
        break;
      case "duration":
        error.duration = value === "" ? "Please choose duration" : "";
        break;
      case "address":
        error.address = value === "" ? "Please choose address" : "";
        break;
      default:
        break;
    }
    setFormData((prevFormData) => {
      // console.log(prevFormData);
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }
  const checkValidation = () => {
    let value = true;
    if (formData?.contact === "") {
      error.contact = "Please enter contact";
      value = false;
    }
    if (formData?.duration === "") {
      error.duration = "Please enter session duration";
      value = false;
    }
    if (formData?.address === "") {
      error.address = "Please enter your address";
      value = false;
    }
    if (formData?.time === "") {
      error.time = "Please enter time";
      value = false;
    }
    if (formData?.date === "" && formData?.selectDay === "") {
      console.log("Enter Data Correctly");
      value = false;
    }
    if (formData?.trainer === "") {
      error.trainer = "Please enter trainer name";
      value = false;
    }
    if (formData?.name === "") {
      error.name = "Please enter trainer name";
      value = false;
    }
    if (formData?.day === "") {
      error.day = "Please enter Days";
      value = false;
    }
    if (formData?.paymentMode === "") {
      error.paymentMode = "Please enter Payment Mode";
      value = false;
    }

    setError((data) => ({
      ...data,
      ...error,
    }));
    return value;
  };
  function submitHandler(e) {
    e.preventDefault();
    if (checkValidation()) {
      console.log("Printing Form-->>", formData);
      navigate(`${App_url?.Booking_Private_Session}`);
    } else {
      console.log("Please Enter Data");
    }
  }
  return (
    <DashboardLayout>
      <div className="main-content">
        <div className="contents pb-10px">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center user-member__title mb-30 mt-30">
                  <h4 className="text-capitalize">Booking Info</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="user-info-tab w-100 bg-white global-shadow radius-xl mb-50">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade  show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="row justify-content-center">
                        <div className="col-xxl-4 col-10">
                          <div className="mt-sm-40 mb-sm-50 mt-20 mb-20">
                            <div className="user-tab-info-title mb-sm-40 mb-20 text-capitalize">
                              <h5 className="fw-500">Book a session</h5>
                            </div>

                            <div className="edit-profile__body">
                              <form onSubmit={submitHandler}>
                                <Row>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <label htmlFor="name">Contact name</label>

                                      <InputGroup
                                        type="text"
                                        required
                                        placeholder={" Your Name Here"}
                                        name={"name"}
                                        onChange={changeHandler}
                                        value={formData.name}
                                        error={error.name}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <label htmlFor="contact">
                                        Contact Number
                                      </label>

                                      <InputGroup
                                        type="tel"
                                        required
                                        placeholder={"+91 1234567890"}
                                        name={"contact"}
                                        onChange={changeHandler}
                                        value={formData.contact}
                                        error={error.contact}
                                      />
                                    </div>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Select Trainer"
                                        inputType="select"
                                        option={trainerTypes}
                                        name={"trainer"}
                                        error={error.trainer}
                                        value={formData.trainer}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Book Session htmlFor"
                                        inputType="select"
                                        option={dayWeek}
                                        name={"day"}
                                        error={error.day}
                                        value={formData.day}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                </Row>

                                <Row>
                                  {formData.day === true ? (
                                    <div className="col-12 col-md-6">
                                      <div className="form-group mb-25">
                                        <InputGroup
                                          label="Date"
                                          type="date"
                                          placeholder={"dd/mm/yyyy"}
                                          name={"date"}
                                          error={error.date}
                                          onChange={changeHandler}
                                          value={formData.date}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-12 col-md-6">
                                      <div className="form-group mb-25">
                                        <InputGroup
                                          label="Select Session Day"
                                          inputType="select"
                                          option={dayTypes}
                                          name={"trainer"}
                                          value={formData?.selectDay}
                                          onChange={changeHandler}
                                        />
                                      </div>
                                    </div>
                                  )}

                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="time"
                                        type="time"
                                        placeholder={"Time"}
                                        name="time"
                                        error={error.time}
                                        onChange={changeHandler}
                                        value={formData.time}
                                      />
                                    </div>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Select Session Duration"
                                        inputType="select"
                                        option={sessionDurationTypes}
                                        name={"duration"}
                                        error={error.duration}
                                        value={formData.duration}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6">
                                    <div className="form-group mb-25">
                                      <InputGroup
                                        label="Payment Mode"
                                        inputType="select"
                                        option={paymentTypes}
                                        name={"paymentMode"}
                                        error={error.paymentMode}
                                        value={formData.paymentMode}
                                        onChange={changeHandler}
                                      />
                                    </div>
                                  </div>
                                </Row>
                                <div className="form-group mb-25">
                                  <InputGroup
                                    label="address"
                                    type="text"
                                    placeholder={"Address"}
                                    name={"address"}
                                    error={error.address}
                                    onChange={changeHandler}
                                    value={formData.address}
                                  />
                                </div>
                                <div className="button-group d-flex pt-sm-25 justify-content-md-end justify-content-start ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-default btn-squared text-capitalize radius-md shadow2 btn-sm"
                                  >
                                    Book
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BookPrivateSessionPage;
