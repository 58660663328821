export const App_url ={
    Add_Member:"/member-management/add-member",
    Member_Management:"/member-management",
    Add_Subscription_Member:"/member-management/add-subscription-member",
    Monthly_Subscription:"/monthly-subscription",
    Dashboard:"/",
    Gym_Tour:"/gym-tour",
    Gym_Tour_Add:"/gym-tour/add-image",
    Booking_Private_Session:"/booking-private-session",
    Booking_Private_Session_Page:"/booking-private-session/booking-private-session-page",
    Workout_Plan:"/workout-plan",
    Add_Workout_Plan:"/workout-plan/add-workout",
    Nutrition_Plan:"/nutrition-plan",
    Add_Nutrition_Plan:"/nutrition-plan/add-nutrition",
}