import React from "react";
import Chart from "chart.js/auto";
import { ArcElement } from "chart.js";
import { Line } from "react-chartjs-2";
Chart.register(ArcElement);

const labels = ["January", "February", "March", "April", "May", "June"];

const data = {
  labels: labels,
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgb(255, 99, 132)",
      data: [0, 10, 5, 20, 80, 2],
    },
  ],
};

const LineChart = () => {
  return (
     <div className="contents">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 md-6">
            <div className="card border-0 ">
              <div
                className="mx-auto mt-30 mb-35"
                style={{ width: 850,height: 450, textAlign:"center" }}
              >
                <Line data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineChart;
