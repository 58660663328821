import React from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import more from "../../../assets/img/svg/more-horizontal.svg";

const ClassesAndSchedule = () => {
  const lists = [
    {
      date: "22 June",
      desc: "Planning for new dashboard wireframe and prototype design",
      time: "10.00 AM",
    },
    {
      date: "22 June",
      desc: "Planning for new dashboard wireframe and prototype design",
      time: "10.00 AM",
    },
    {
      date: "22 June",
      desc: "Planning for new dashboard wireframe and prototype design",
      time: "10.00 AM",
    },
    {
      date: "22 June",
      desc: "Planning for new dashboard wireframe and prototype design",
      time: "10.00 AM",
    },
  ];
  return (
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 md-6  mb-25">
              <div className="card border-0 px-25 pb-10 project-task-list--event">
                <div className="card-header px-0 border-0">
                  <h6>Upcoming Events</h6>
                  <div className="card-extra me-1">
                    <ul className="card-tab-links nav-tabs nav" role="tablist">
                      <li>
                        <Link
                          className="active"
                          href="#"
                          data-bs-toggle="tab"
                          id="t_selling-today2222-tab"
                          role="tab"
                          aria-selected="true"
                        >
                          Today
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="#"
                          data-bs-toggle="tab"
                          id="t_selling-week2222-tab"
                          role="tab"
                          aria-selected="true"
                        >
                          Week
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="#"
                          data-bs-toggle="tab"
                          id="t_selling-month3333-tab"
                          role="tab"
                          aria-selected="true"
                        >
                          Month
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="t_selling-today2222"
                      role="tabpanel"
                      aria-labelledby="t_selling-today2222-tab"
                    >
                      <div className="project-task table-responsive table-responsive--dynamic">
                        <table className="table table-borderless">
                          <tbody>
                            {lists &&
                              lists.map((list, index) => (
                                <tr key={index} className="project-task-list">
                                  <td>
                                    <div className="event-Wrapper">
                                      <div className="event-Wrapper__left">
                                        <div className="event-wrapper-item bg-primary">
                                          {list.date}
                                        </div>
                                      </div>
                                      <div className="event-Wrapper__right">
                                        <h6>{list.desc}</h6>
                                        <span>{list.time}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="dropdown dropleft">
                                      <Link
                                        href="#"
                                        className="btn-link"
                                        role="button"
                                        id="Today"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img
                                          src={more}
                                          alt="more-horizontal"
                                          className="svg"
                                        />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ClassesAndSchedule;
