import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { ArcElement } from "chart.js";
Chart.register(ArcElement);
export default function SubscriptionPieChart() {
  const data = {
    labels: ["Basic", "Standard", "Premium"],
    datasets: [
      {
        label: "% Of Subscribers",
        data: [33,33,33],
        backgroundColor: ["#007D9C", "#244D70", "#D123B3"],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (

    <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 md-6">
              <div className="card border-0 project-task-list--event">
              <div
            className="mx-auto mt-40 mb-35"
            style={{ width: 450, textAlign: "center" }}
          >
            <h1 style={{ fontFamily: "monospace" }}>
              Subscription Members Dividation
            </h1>
            <Pie data={data} width={50} height={50} />
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
