import "./App.css";
import React from "react";
// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/layouts/Dashboard";
import ManageMembers from "./components/member-management/ManageMembers";
import AddMember from "./components/member-management/AddMember";
import MonthlySubscription from "./components/monthly-subscription/MonthlySubscription";
import { App_url } from "./components/common/Constant";
import GymTourPage from "./components/gym-tour/GymTourPage";
import BookingPrivateSession from "./components/booking-session/BookingPrivateSession";
import BookPrivateSessionPage from "./components/booking-session/BookPrivateSessionPage";
import AddSubscriptionMemberForm from "./components/monthly-subscription/AddSubscriptionMemberForm";
import AddImageToGymTour from "./components/gym-tour/AddImageToGymTour";
import WorkoutPlan from "./components/workout-nutritional-plan/WorkoutPlan";
import AddWorkoutPage from "./components/workout-nutritional-plan/AddWorkoutPage";
import NutritionPlan from "./components/nutrition-plan/NutritionPlan";
import AddNutritionPage from "./components/nutrition-plan/AddNutritionPage";
// import { initailData } from "./redux/reducers/Reducer";
// import { setStoreCuurentUser } from "./redux/actions/Action";
// import LoginForm from "./components/UsersCred/LoginForm";

function App() {
  // const { currentUser } = useSelector((state) => state?.allReducers);
  // const dispatch = useDispatch();
  // console.log("currentUser", currentUser);

  // const ReduxInitial = () => {
  //   if (currentUser === undefined) {
  //     dispatch(setStoreCuurentUser(initailData?.currentUser));
  //   }
  // };
  // useEffect(() => {
  //   ReduxInitial();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <BrowserRouter>
      <Routes>
          <React.Fragment>
            <Route path="/" exact element={<Dashboard />} />
            <Route
              path={App_url.Member_Management}
              element={<ManageMembers />}
            />
            <Route path={App_url.Add_Member} element={<AddMember />} />
            <Route path="/manage-member/:id" element={<ManageMembers />} />
            <Route
              path={App_url.Monthly_Subscription}
              element={<MonthlySubscription />}
            />
            <Route
              path={App_url.Add_Subscription_Member}
              element={<AddSubscriptionMemberForm />}
            />
            <Route
              path={App_url.Booking_Private_Session}
              element={<BookingPrivateSession />}
            />
            <Route path={App_url.Gym_Tour} element={<GymTourPage />} />
            <Route
              path={App_url.Gym_Tour_Add}
              element={<AddImageToGymTour />}
            />
            <Route
              path={App_url.Booking_Private_Session_Page}
              element={<BookPrivateSessionPage />}
            />
            <Route path={App_url.Workout_Plan} element={<WorkoutPlan />} />
            <Route
              path={App_url.Add_Workout_Plan}
              element={<AddWorkoutPage />}
            />
            <Route path={App_url.Nutrition_Plan} element={<NutritionPlan />} />
            <Route
              path={App_url.Add_Nutrition_Plan}
              element={<AddNutritionPage />}
            />
          </React.Fragment>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
