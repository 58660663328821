import { ActionTypes } from "../actions/Action";

export const initailData = {
    member:[],
    trainer:[],   
    currentUser:{
        email:"",
        password:"", 
        id:"",
    }
};

export const Reducers = (state = initailData, action) => {
  switch (action.type) {
    case ActionTypes.SET_STORE_USER_LOGIN:
        console.log("User Login", action?.payload)
        return {
            ...state,
            currentUser:action?.payload
        }
    case ActionTypes.SET_STORE_ADD_MEMBER:
        console.log("SUSACTION-->>",action.payload)
        let DataArray = state?.member;
        if(action?.payload !== null){
            DataArray.push(action.payload);
        }else{
            return {
                ...state,
                member:DataArray
              }
        }
        console.log('DataArray', DataArray)
      return {
        ...state,
        member:DataArray
      }
    case ActionTypes.SET_STORE_EDIT_MEMBER:
        const StateData = state?.member?.map((item)=>{
            if(item?.id === action?.payload?.id){
                return {
                    ...item,
                    ...action?.payload
                };
            }else{
                return {...item};
            }
        })
        return {
            ...state,
            member:StateData
        }
    case ActionTypes.SET_STORE_DELETE_MEMBER:
        const DelSusData = state.member.filter((user) => user.id !== action.payload);
        return {
            ...state,
            member:DelSusData
        }
    // end of member

    case ActionTypes.ADD_PLAN:

        let DataArrayPlan = state?.plan;
        if(action?.payload !== null){
            DataArrayPlan.push(action.payload);
        }else{
            return {
                ...state,
                plan:DataArrayPlan
              }
        }
        console.log('DataArray', DataArrayPlan)
      return {
        ...state,
        plan:DataArrayPlan
      }
    case ActionTypes.EDIT_PLAN:
        const StateDataPlan = state?.plan?.map((item)=>{
            if(item?.id === action?.payload?.id){
                return {
                    ...item,
                    ...action?.payload
                };
            }else{
                return {...item};
            }
        })
        return {
            ...state,
            plan:StateDataPlan
        }
    case ActionTypes.DEL_PLAN:
        const DelSusDataCoach = state.plan.filter((user) => user.id !== action.payload);
        return {
            ...state,
            plan:DelSusDataCoach
        }

        // end of plan

    case ActionTypes.SET_STORE_ADD_TRAINER:
      
        let DataArrayCoach = state?.trainer;
        if(action?.payload !== null){
            DataArrayCoach.push(action.payload);
        }else{
            return {
                ...state,
                trainer:DataArrayCoach
              }
        }
        console.log('DataArray', DataArrayCoach)
      return {
        ...state,
        trainer:DataArrayCoach
      }
    case ActionTypes.SET_STORE_EDIT_TRAINER:
        const StateDataCoach = state?.trainer?.map((item)=>{
            if(item?.id === action?.payload?.id){
                return {
                    ...item,
                    ...action?.payload
                };
            }else{
                return {...item};
            }
        })
        return {
            ...state,
            trainer:StateDataCoach
        }
    case ActionTypes.SET_STORE_DELETE_TRAINER:
        const DelCoachData = state.trainer.filter((user) => user.id !== action.payload);
        return {
            ...state,
            trainer:DelCoachData
        }

        // End Of Coach
    
    default:
      return state;
  }
};