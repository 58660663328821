import React from "react";
import DashboardLayout from "../layouts/DashboardLayout";
import search from "../../assets/img/svg/search.svg";
import workout0 from "../../assets/img/workouts/Gym Workout 0.png";
import { Link } from "react-router-dom";
import { App_url } from "../common/Constant";
import { useEffect } from "react";

const NutritionPlan = () => {
  useEffect(() => {
    document.title = "Nutrition plan";
  }, []);

  const Lists = [
    {
      title: "Egg Veggie",
      desc: "add description of that particular workout here. the description may have long",
      calories: "20",
      category: "Premium",
    },
    {
      title: "Banana Shake",
      desc: "add description of that particular workout here. the description may have long",
      calories: "20",
      category: "Premium",
    },
    {
      title: "Milk",
      desc: "add description of that particular workout here. the description may have long",
      calories: "20",
      category: "Premium",
    },
  ];
  return (
    <DashboardLayout>
      <div className="main-content">
        <div className="contents">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-main user-member justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center user-member__title justify-content-center me-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                        Nutrition List
                      </h4>
                    </div>
                    <form className="d-flex align-items-center user-member__form my-sm-0 my-2">
                      <img src={search} alt="search" className="svg" />
                      <input
                        className="form-control me-sm-2 border-0 box-shadow-none"
                        type="search"
                        placeholder="Search by Name"
                        aria-label="Search"
                      />
                    </form>
                  </div>
                  <div className="action-btn">
                    <Link
                      to={App_url.Add_Nutrition_Plan}
                      className="btn px-15 btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#new-member"
                    >
                      <i className="las la-plus fs-16" />
                      Add Nutrition
                    </Link>
                    <div
                      className="modal fade new-member "
                      id="new-member"
                      role="dialog"
                      tabIndex={-1}
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {Lists &&
                Lists.map((list, index) => (
                  <div key={index} className="col-md-6 col-sm-12 mb-25">
                    <div className="media  py-30  ps-30 pe-20 bg-white radius-xl users-list ">
                      <img
                        className=" me-20 rounded-circle wh-80 bg-opacity-primary  "
                        src={workout0}
                        alt="img"
                      />
                      <div className="media-body d-xl-flex users-list-body">
                        <div className="flex-1 pe-xl-30 users-list-body__title">
                          <h6 className="mt-0 fw-500">{list.title} </h6>
                          <p className="mb-0">{list.desc}</p>
                          <div className="users-list-body__bottom">
                            <span>
                              <span className="fw-600">{list.calories}</span>{" "}
                              Calories
                            </span>
                            <span className="ms-15">
                              Category:
                              <span className="fw-600">{list.category} </span>
                            </span>
                          </div>
                        </div>
                        <div className="users-list__button mt-xl-0 mt-15">
                          <button className="btn btn-primary btn-default btn-squared text-capitalize px-20 mb-10 global-shadow">
                            Edit
                          </button>
                          <button
                            type="button"
                            className="border text-capitalize px-25 color-gray transparent shadow2 follow my-xl-0 radius-md"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NutritionPlan;
