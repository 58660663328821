import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { App_url } from "../common/Constant";
import InputGroup from "../common/InputGroup";
import DashboardLayout from "../layouts/DashboardLayout";
import { useEffect } from "react";
const AddImageToGymTour = () => {
  useEffect(() => {
    document.title = "Add image";
  }, []);
  const imageTypes = [
    {
      title: "",
      value: "",
    },
    {
      title: "Machinaries",
      value: "Machinaries",
    },
    {
      title: "Dumbells",
      value: "Dumbells",
    },
    {
      title: "Yoga",
      value: "Yoga",
    },
    {
      title: "Architecture",
      value: "Architecture",
    },
  ];
  const navigate = useNavigate();
  const [error, setError] = useState({
    category: "",
  });
  const [formData, setFormData] = useState({
    category: "",
  });

  function changeHandler(event) {
    const { name, value, checked, type } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  const checkValidation = () => {
    let value = true;
    if (formData?.category === "") {
      error.category = "Please enter image type";
      value = false;
    }
    setError((data) => ({
      ...data,
      ...error,
    }));
    return value;
  };

  function submitHandler(e) {
    e.preventDefault();
    if (checkValidation()) {
      console.log("Printing Form-->>", formData);
      navigate(`${App_url.Gym_Tour}`);
    } else {
      console.log("Please Enter Data");
    }
  }

  return (
    <DashboardLayout>
      <div className="main-content">
        <div className="contents">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center user-member__title mb-30 mt-30">
                  <h4 className="text-capitalize">Add Image</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="user-info-tab w-100 bg-white global-shadow radius-xl mb-50">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade  show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="row justify-content-center">
                        <div className="col-xxl-4 col-10">
                          <div className="mt-sm-40 mb-sm-50 mt-20 mb-20">
                            <div className="user-tab-info-title mb-sm-40 mb-20 text-capitalize">
                              <h5 className="fw-500">Choose Image</h5>
                            </div>

                            <div className="edit-profile__body">
                              <form onSubmit={submitHandler}>
                                <div className="form-group mb-25">
                                  <label
                                    htmlFor="images"
                                    className="drop-container"
                                  >
                                    <span className="drop-title">
                                      Drop files here
                                    </span>
                                    or
                                    <input
                                      type="file"
                                      name="image"
                                      value={formData.image}
                                      onChange={changeHandler}
                                      id="images"
                                      accept="image/*"
                                      required
                                    />
                                  </label>
                                </div>
                                <div className="form-group mb-25">
                                  <InputGroup
                                    label="Image Type"
                                    inputType="select"
                                    option={imageTypes}
                                    name={"category"}
                                    error={error.category}
                                    value={formData.category}
                                    onChange={changeHandler}
                                  />
                                </div>
                                <div className="button-group d-flex pt-sm-25 justify-content-md-end justify-content-start ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-default btn-squared text-capitalize radius-md shadow2 btn-sm"
                                  >
                                    Save &amp; Next
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddImageToGymTour;
