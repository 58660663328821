import React from "react";
import { Link } from "react-router-dom";
import edit from "../../assets/img/svg/edit.svg";
import trash from "../../assets/img/svg/trash-2.svg";
import DashboardLayout from "../layouts/DashboardLayout";
import { App_url } from "../common/Constant";
import { useEffect } from "react";


const BookingPrivateSession = () => {
  useEffect(() => {
    document.title = "Book a session"
  }, []);
  const deleteHandler = (id) => {
    // dispatch(DelStud(id));
    console.log("Deleting..", id);
  };
  const users = [
    {
      user_name: "Ashish Rangdal",
      trainer: "Rohit",
      amount: "199",
      upcoming_date: "12/6/2023",
      timing: "12.30:PM",
    },
    {
      user_name: "Rohit Sharma",
      trainer: "Rohit",
      amount: "199",
      upcoming_date: "12/6/2023",
      timing: "12.30:PM",
    },
    {
      user_name: "Virat Kohli",
      trainer: "Rohit",
      amount: "199",
      upcoming_date: "12/6/2023",
      timing: "12.30:PM",
    },
  ];
  return (
    <DashboardLayout>
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mt-30">
              <div className="card mb-25">
                <div className="card-header">
                  <h6>Booked Members List</h6>
                  <div className="action-btn">
                    <Link
                      to={App_url.Booking_Private_Session_Page}
                      className="btn px-15 btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#new-member"
                    >
                      <i className="las la-plus fs-16"></i>Add Session
                    </Link>
                  </div>
                </div>

                <div className="card-body pt-0 pb-0" style={{lineHeight:"3"}}>
                  <div className="drag-drop-wrap">
                    <div className=" table-responsive-lg w-100 mb-1 0">
                      <table className="table mb-0 table-basic">
                        <thead>
                          <tr>
                            <th>UserName</th>
                            <th>Trainer</th>
                            <th>Amount</th>
                            <th>Upcoming Date</th>
                            <th>Timing</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users &&
                            users.map((user,index) => (
                              <tr key={index} className="draggable" draggable="true">
                                <td>
                                  <div className="item d-flex align-items-center">
                                    <div className=" d-flex align-items-center">
                                      <div className="item_title">
                                        <h6>
                                          <Link to="#">{user.user_name}</Link>
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>{user.trainer}</td>
                                <td>{user.amount}</td>

                                <td>
                                  <span className="date">{user.upcoming_date}</span>
                                </td>
                                <td>
                                  <span className="date">
                                    {user.timing}
                                  </span>
                                </td>
                                <td>
                                  <div className="table-actions">
                                    <Link
                                      to={`/edit-user/${user?.id}`}
                                      state={user}
                                    >
                                      <img
                                        className="svg"
                                        src={edit}
                                        alt="edit"
                                      />
                                    </Link>
                                    <Link onClick={deleteHandler}>
                                      <img
                                        className="svg"
                                        src={trash}
                                        alt="trash"
                                      />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BookingPrivateSession;
