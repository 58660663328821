import React from 'react'
import PropTypes from "prop-types"
import { FloatingLabel, Form, FormSelect, InputGroup as ReactInputGroup } from 'react-bootstrap';
import { useMemo } from 'react';
import { uuidv4 } from './utils';

export const FormGroupControl = (props) =>{
    if(props?.labelFloat){
        return(
            <FloatingLabel label={props?.label} labelFloat={props?.labelFloat}  className={props?.className} controlId={props?.controlId}>
                {props?.children}
            </FloatingLabel>
        )
    }
    return(
        <Form.Group  className={props?.className} controlId={props?.controlId}>
            {props?.children}
        </Form.Group>
    )
}
export default function InputGroup(props) {
    const {
        className,
        label,
        onChange,
        error,
        name,
        type,
        id,
        formClassName,
        labelClassName,
        errorClassName,
        placeholder,
    } = props;
    let ids = useMemo(()=>uuidv4(), []); // removed name dependency from here
    // let FormGroupControl = Form.Group
    const data = {};
    if(props?.as){
        data.as = props?.as
    }
    const InputForm = () =>{

        if(props?.inputType === "select"){
            return(
                <FormSelect name={name} id={id} className={className} value={props?.value} isInvalid={error?true:false} onChange={onChange}>
                    {props?.option?.length === 0?
                        <option  disabled={true} value={""}>No data found</option>
                    :
                    props?.option?.map((item, index)=>(
                        <option key={index?.toString()} disabled={item?.disabled} value={item?.value}>{item?.title}</option>
                    ))}
                </FormSelect>
            )
        }
        const FormControl = () =>{
            return(
                <Form.Control
                    id={id}
                    type={type}
                    onWheel={(e)=>e.target.blur()}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    className={className}
                    {...data}
                    value={props?.value}
                    isInvalid={error?true:false}
                />
            )
        }
        if(props?.leftLabel||props?.rightLabel){
            return(
                <ReactInputGroup >
                   {props?.leftLabel &&(
                    <ReactInputGroup.Text id={`left_icon_${id}`} className={` ${props?.leftIconClick?"cursor-pointer":""}`}>
                        {props?.leftLabel}
                    </ReactInputGroup.Text>
                   )}
                    {FormControl()}
                    {props?.rightLabel &&(
                    <ReactInputGroup.Text id={`left_icon_${id}`} className={` ${props?.leftIconClick?"cursor-pointer":""}`}>
                        {props?.rightLabel}
                    </ReactInputGroup.Text>
                   )}
                </ReactInputGroup>
            )
        }
        return(
            FormControl()
        )
    }
    const LabelForm = () =>{
        return (
            label && !props?.labelFloat ?(
                <React.Fragment>
                    <Form.Label className={`${labelClassName}`}>{label} {props?.required&&<span style={{ color: "red" }}>*</span>}</Form.Label> 
                </React.Fragment>
            ):<React.Fragment></React.Fragment>
        )
    }
    const ErrorForm = () =>{
        return (
            error ?(
                <React.Fragment>
                    <Form.Control.Feedback type="invalid" className={`${errorClassName}`}>{error}</Form.Control.Feedback>
                </React.Fragment>
            ):<React.Fragment></React.Fragment>
        )
    }
    // if(props?.inputType === "select"){
    //     return(
    //         <FormGroupControl label={label} labelFloat={props?.labelFloat}  className={`form_group mt-0 form_style_${props?.formStyle} form-size-${props?.size} radius-${props?.radius} ${formClassName} ${error?"form-validate":""} form_variate`} controlId={ids}>
    //             <LabelForm/>
    //                 <InputForm/>
    //             <ErrorForm/>
    //         </FormGroupControl>
    //     )
    // }
  return (
    <FormGroupControl label={label} labelFloat={props?.labelFloat}  className={`form_group ${props?.leftIcon||props?.rightIcon || props?.rightLabel || props?.leftLabel ?"input_group_icon":""} mt-0 form_style_${props?.formStyle} form-size-${props?.size} radius-${props?.radius} ${formClassName} ${error?"form-validate":""} form_variate`} controlId={ids}>
        {LabelForm()}
            {InputForm()}
        {ErrorForm()}
    </FormGroupControl>
  )
}
InputGroup.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.any,
    onChange: PropTypes.func,
    error: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    formClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    errorClassName: PropTypes.string,
    as:PropTypes.any,
    radius:PropTypes.number,
    size:PropTypes.number,
    labelFloat:PropTypes?.bool,
    value:PropTypes?.any,
    formStyle:PropTypes.any,
    required:PropTypes?.bool,
    inputType:PropTypes?.string,
    option:PropTypes?.array,
    leftIcon:PropTypes?.any,
    rightIcon:PropTypes?.any,
    leftLabel:PropTypes?.any,
    rightLabel:PropTypes?.any,
    leftIconClass:PropTypes?.any,
    rightIconClass:PropTypes?.any,
    onEditorChange:PropTypes.func,
    rightIconClick:PropTypes.func,
    leftIconClick:PropTypes.func,
}
InputGroup.defaultProps = {
    className: "",
    label: "",
    placeholder:"",
    onChange: ()=>{},
    onEditorChange: ()=>{},
    error: "",
    name: "name",
    type: "text",
    id: "",
    formClassName: "",
    labelClassName: "",
    errorClassName: "",
    as:"",
    radius:1,
    size:2,
    labelFloat:false,
    value:"",
    formStyle:"normal",
    required:false,
    inputType:"input",
    option:[],
    leftIcon:"",
    rightIcon:"",
    leftIconClass:"",
    rightIconClass:"",
    leftLabel:"",
    rightLabel:"",
}