import React from "react";
import { Link } from "react-router-dom";
import edit from "../../assets/img/svg/edit.svg";
import search from "../../assets/img/svg/search.svg";
import trash from "../../assets/img/svg/trash-2.svg";
import DashboardLayout from "../layouts/DashboardLayout";
import { App_url } from "../common/Constant";
import { useEffect } from "react";
export const MonthlySubscriptionChild = () =>{
  useEffect(() => {
    document.title = "Subscription Members"
  }, []);
  const deleteHandler = (id) => {
    // dispatch(DelStud(id));
    console.log("Deleting..", id);
  };
  const users = [
    {
      user_name: "Ashish Rangdal",
      plan: "Premium",
      amount: "199",
      join_date: "12/6/2023",
      expiry_date: "13/7/2023",
    },
    {
      user_name: "Rohit Sharma",
      plan: "Premium",
      amount: "199",
      join_date: "12/6/2023",
      expiry_date: "13/7/2023",
    },
    {
      user_name: "Virat Kohli",
      plan: "Premium",
      amount: "199",
      join_date: "12/6/2023",
      expiry_date: "13/7/2023",
    },
  ];
  return (
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mt-30">
              <div className="card mb-25">
                <div className="card-header">
                  <h6>Subscribed Members List</h6>
                  <form className="d-flex align-items-center user-member__form my-sm-0 my-2">
                      <img src={search} alt="search" className="svg" />
                      <input
                        className="form-control me-sm-2 border-0 box-shadow-none"
                        type="search"
                        placeholder="Search by Name"
                        aria-label="Search"
                      />
                    </form>
                  <div className="action-btn">
                    <Link
                      to={App_url.Add_Subscription_Member}
                      className="btn px-15 btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#new-member"
                    >
                      <i className="las la-plus fs-16"></i>Add Members
                    </Link>
                  </div>
                </div>

                <div className="card-body pt-0 pb-0" style={{lineHeight:"3"}}>
                  <div className="drag-drop-wrap">
                    <div className=" table-responsive-lg w-100 mb-1 0">
                      <table className="table mb-0 table-basic">
                        <thead>
                          <tr>
                            <th>UserName</th>
                            <th>Plan</th>
                            <th>Amount</th>
                            <th>Joining Date</th>
                            <th>Expiry Date</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users &&
                            users.map((user , index) => (
                              <tr key={index} className="draggable" draggable="true">
                                <td>
                                  <div className="item d-flex align-items-center">
                                    <div className=" d-flex align-items-center">
                                      <div className="item_title">
                                        <h6>
                                          <Link to="#">{user.user_name}</Link>
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>{user.plan}</td>
                                <td>{user.amount}</td>

                                <td>
                                  <span className="date">{user.join_date}</span>
                                </td>
                                <td>
                                  <span className="date">
                                    {user.expiry_date}
                                  </span>
                                </td>
                                <td>
                                  <div className="table-actions">
                                    <Link
                                      to={`/edit-user/${user?.id}`}
                                      state={user}
                                    >
                                      <img
                                        className="svg"
                                        src={edit}
                                        alt="edit"
                                      />
                                    </Link>
                                    <Link onClick={deleteHandler}>
                                      <img
                                        className="svg"
                                        src={trash}
                                        alt="trash"
                                      />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
const MonthlySubscription = () => {
  
  return (
    <DashboardLayout>
      <MonthlySubscriptionChild/>
    </DashboardLayout>
  );
};

export default MonthlySubscription;
